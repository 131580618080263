import React, {createContext, useState, useContext, useEffect, ReactNode} from 'react';
import {request} from '../../../Infrastructure';
import {
    getProductDescription,
    getProductRelated,
    getProductAttributes,
    getAutoRelatedSkus,
    getAutoRelatedProducts,
} from '../../queries';
import {useQuery} from "@tanstack/react-query";

interface ProductAttributes {
    id;
    sku;
    name;
    type_id: string;
    stock_status: string;
    small_image?: { label: string; };
    customProductImageUrl?: { url: string; };
    custom_attributes?: {
        attribute_code: string;
        value: string;
    }[];
    categories?: {
        id: number;
        name: string;
        url_path: string;
    }[];
    price_range?: {
        minimum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
        discount?: {
            amount_off: { value: number; currency: string };
            percent_off: { value: number; currency: string }
        }
    };
    reviews?: {
        items?: {
            summary: string;
            text: string;
            nickname: string;
            created_at: string;
            average_rating: number;
            ratings_breakdown: {
                name: string;
                value: string;
            }[];
        }[];
    };
}

interface ProductDetails {
    id: string;
    sku: string;
    name: string;
    description?: {
        html
    };
    short_description?: {
        html
    };
    specificatii_tehnice;
}

interface ProductRelated {
    upsell_products?: {
        id;
        sku;
        name;
        type_id: string;
        small_image?: { label: string; };
        customProductImageUrl?: { url: string; };
        price_range: {
            minimum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string };
                discount: { amount_off: number; percent_off: number }
            };
            maximum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string }
            }
        };
        url_rewrites: {
            url: string;
            parameters?: {
                name: string;
                value: string;
            }[];
        }[];
        custom_attributes?: {
            attribute_code: string;
            value: string;
        }[];
    }[];
    crosssell_products?: {
        id;
        sku;
        name;
        type_id: string;
        small_image?: { label: string; };
        customProductImageUrl?: { url: string; };
        price_range?: {
            minimum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string };
                discount: { amount_off: number; percent_off: number }
            };
            maximum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string }
            }
        };
        url_rewrites?: {
            url: string;
            parameters?: {
                name: string;
                value: string;
            }[];
        }[];
        custom_attributes?: {
            attribute_code: string;
            value: string;
        }[];
    }[];
    related_products?: {
        id;
        sku;
        name;
        type_id: string;
        small_image?: { label: string; };
        customProductImageUrl?: { url: string; };
        price_range?: {
            minimum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string };
                discount: { amount_off: number; percent_off: number }
            };
            maximum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string }
            }
        };
        url_rewrites?: {
            url: string;
            parameters?: {
                name: string;
                value: string;
            }[];
        }[];
        custom_attributes?: {
            attribute_code: string;
            value: string;
        }[];
    }[];
}

interface AutoRelatedSkus {
    id: string;
    sku: string;
    name: string;
}

interface AutoRelatedProducts {
    id;
    sku;
    name;
    type_id: string;
    small_image?: { label: string; };
    customProductImageUrl?: { url: string; };
    price_range: {
        minimum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
    };
    url_rewrites: {
        url: string;
        parameters?: {
            name: string;
            value: string;
        }[];
    }[];
    custom_attributes?: {
        attribute_code: string;
        value: string;
    }[];
}

declare global {
    interface Window {
        appConfig: {
            attributes: { [key: string]: AttributeType };
        };
    }
}
type AttributeType = {
    label: string;
    value: string;
    code: string;
};

type ConfigType = {
    attributes: { [key: string]: AttributeType };
};

interface ProductAttributeContextValue {
    productSku: string;
    productAttributes: ProductAttributes | null;
    loading: boolean;
    error: any;
    appConfig: ConfigType;
    upsellProducts: any[];
    relatedProducts: any[];
    crossSellProducts: any[];
    autoRelatedProducts: any[];
    productName: string;
    specificatii: string;
    shortDescription: string;
    description: string;
    customAttributes: {
        attribute_code: string;
        value: string;
    }[] | null;
    categories: {
        id: number;
        name: string;
        url_path: string;
    }[] | null;
}

const ProductAttributeContext = createContext<ProductAttributeContextValue | undefined>(undefined);

export const ProductAttributeProvider: React.FC<{ children: ReactNode, productSku: string }> = (
    {
        children,
        productSku
    }
) => {
    const [productDetails, setProductDetails] = useState<ProductDetails | null>(null);
    const [productRelated, setProductRelated] = useState<ProductRelated | null>(null);
    const [productAttributes, setProductAttributes] = useState<ProductAttributes | null>(null);
    const [autoRelatedSkus, setAutoRelatedSkus] = useState<AutoRelatedSkus[] | []>([]);
    const [autoRelatedProducts, setAutoRelatedProducts] = useState<AutoRelatedProducts[] | []>([]);
    const [appConfig, setAppConfig] = useState<ConfigType>({attributes: {}});
    const upsellProducts = productRelated?.upsell_products || [];
    const relatedProducts = productRelated?.related_products || [];
    const crossSellProducts = productRelated?.crosssell_products || [];
    const specificatii = productDetails?.specificatii_tehnice || null;
    const shortDescription = productDetails?.short_description.html || null;
    const description = productDetails?.description.html || null;
    const productName = productAttributes?.name || null;
    const customAttributes = productAttributes?.custom_attributes || null;
    const categories = productAttributes?.categories || null;

    const productDetailsQuery = useQuery({
        queryKey: ['productDetails'],
        queryFn: async () => {
            return request(getProductDescription, {sku: productSku});
        },
        enabled: true,
        // staleTime: 300000,
        refetchOnWindowFocus: false,
    });

    const productRelatedQuery = useQuery({
        queryKey: ['productRelated'],
        queryFn: async () => {
            return request(getProductRelated, {sku: productSku});
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const productAttributesQuery = useQuery({
        queryKey: ['productAttributes'],
        queryFn: async () => {
            return request(getProductAttributes, {sku: productSku});
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const autoRelatedSkusQuery = useQuery({
        queryKey: ['autoRelatedSkus', productSku],
        queryFn: async () => {
            const variables = {sku: productSku};
            return request(getAutoRelatedSkus, variables);
        },
        enabled: productSku !== null,
        refetchOnWindowFocus: false,
    });

    const autoRelatedProductsQuery = useQuery({
        queryKey: ['autoRelatedProducts', autoRelatedSkus],
        queryFn: async () => {
            const variables = {skus: autoRelatedSkus};
            return request(getAutoRelatedProducts, variables);
        },
        enabled: autoRelatedSkus?.length > 0,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (productDetailsQuery?.data) {
            setProductDetails(productDetailsQuery?.data?.data?.products?.items[0]);
        }

        if (productRelatedQuery?.data) {
            setProductRelated(productRelatedQuery?.data?.data?.products?.items[0]);
        }

        if (productAttributesQuery?.data) {
            setProductAttributes(productAttributesQuery?.data?.data?.products?.items[0]);
        }

        if (autoRelatedSkusQuery?.data) {
            const skus = autoRelatedSkusQuery?.data?.data?.getAutoRelatedSkus.map(product => product.sku);
            setAutoRelatedSkus(skus);
        }

        if (autoRelatedProductsQuery?.data) {
            setAutoRelatedProducts(autoRelatedProductsQuery?.data?.data?.products?.items);
        }

    }, [
        productAttributesQuery.data,
        productDetailsQuery.data,
        productRelatedQuery.data,
        autoRelatedSkusQuery.data,
        autoRelatedProductsQuery.data
    ]);

    useEffect(() => {
        if (window.appConfig) {
            setAppConfig(window.appConfig);
        }
    }, []);

    return (
        <ProductAttributeContext.Provider value={
            {
                productAttributes,
                loading: productAttributesQuery.isLoading,
                error: productAttributesQuery.error,
                appConfig,
                upsellProducts,
                relatedProducts,
                crossSellProducts,
                autoRelatedProducts,
                productName,
                productSku,
                specificatii,
                shortDescription,
                description,
                customAttributes,
                categories
            }
        }>
            {children}
        </ProductAttributeContext.Provider>
    );
};

export const useProductAttributes = () => {
    const context = useContext(ProductAttributeContext);
    if (!context) {
        throw new Error('useProductAttributes must be used within a ProductAttributeProvider');
    }
    return context;
};
