import React from "react";
import {ProductAttributeProvider} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";
import {AddToCartButton} from "./AddToCart/AddToCartButton";
import {CheckoutProvider} from "../../../Checkout";

interface ProductSku {
    productSku: string;
    oldCrmEnabled?: boolean;
    newCrmEnabled?: boolean;
}

const queryClient = client();

export const AddToCart: React.FC<ProductSku> = ({productSku, oldCrmEnabled, newCrmEnabled}) => {

    console.log('AddToCart', productSku, oldCrmEnabled, newCrmEnabled)

    return (
        <QueryClientProvider client={queryClient}>
            <CheckoutProvider>
                <ProductAttributeProvider productSku={productSku} >
                    <AddToCartButton oldCrmEnabled={oldCrmEnabled} newCrmEnabled={newCrmEnabled}/>
                </ProductAttributeProvider>
            </CheckoutProvider>
        </QueryClientProvider>
    );
}
