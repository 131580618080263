import React, {useMemo, useState} from 'react';
import {ExpandButton, useWindowSize} from '../../../../../general';
import {useProductAttributes} from '../../../state';

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const Specificatii = () => {
    const {productName, specificatii} = useProductAttributes() || {};
    const {isMobile} = useWindowSize();
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const decodedSpecificatiiTehnice = useMemo(() => {
        return specificatii ? decodeHtml(specificatii) : null;
    }, [specificatii]);

    if (!specificatii) {
        return null;
    }

    const handleButtonClick = () => {
        toggleExpand();
        const element = document.getElementById('specificatiiTehnice');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    return (
        <div>
            {isMobile ? (
                <>
                    <div className={' bg-second-btn-bg bg-gray-300 font-custom-gray'}  id={'specificatiiTehnice'}>
                        <h2
                            className={'font-montserrat font-bold text-lg text-start text-white focus:text-white leading-[50px] pl-10 pr-10'}>
                            {'Specificatii tehnice'}
                        </h2>
                    </div>
                    <div className={'relative rounded-xl md:mt-8 bg-white md:mb-20 mb-10'}>
                        <div
                            className={`react-css overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-full' : 'max-h-[450px]'} md:mt-5 p-6`}>
                            <div className={'react-css'}>
                                <h2 className={'react-css text-2xl font-black text-left text-custom-gray font-montserrat mb-8 flex'}>
                                    <span className={'react-css w-2/5 md:block hidden'}>Specificatii tehnice</span>
                                    <span
                                        className={'react-css text-custom-gray font-montserrat font-bold text-center text-2xl'}>{productName}</span>
                                </h2>
                            </div>
                            {decodedSpecificatiiTehnice ? (
                                <div dangerouslySetInnerHTML={{__html: decodedSpecificatiiTehnice}}
                                     className={'table-with-border'}/>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </div>
                        {/* opacity efect over the closed div */}
                        <div
                            className={`absolute top-0 left-0 w-full h-full transition-all duration-300 ${isExpanded ? 'hidden' : 'bg-custom-gradient'} rounded-2xl`}></div>
                        {/* The "See More/Less" button */}
                        <div className={'react-css flex items-center justify-center md:mb-0 mb-5'}>
                            <ExpandButton
                                isExpanded={isExpanded}
                                onClick={handleButtonClick}
                                expandedText="Ascunde specificatiile"
                                collapsedText="Vezi toate specificatiile"
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className={'relative rounded-xl mt-8 bg-white md:mb-20 mb-10'} id={'specificatiiTehnice'}>
                    <div
                        className={`react-css overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-full' : 'max-h-[450px]'} mt-5 p-6`}>
                        <div className={'react-css'}>
                            <h2 className={'react-css text-2xl font-black text-left text-custom-gray font-montserrat mb-8 flex'}>
                                <span className={'react-css w-2/5 md:block hidden'}>Specificatii tehnice</span>
                                <span
                                    className={'react-css text-custom-gray font-montserrat font-bold text-center text-2xl'}>{productName}</span>
                            </h2>
                        </div>
                        {decodedSpecificatiiTehnice ? (
                            <div dangerouslySetInnerHTML={{__html: decodedSpecificatiiTehnice}}
                                 className={'table-with-border'}/>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </div>
                    {/* opacity efect over the closed div */}
                    <div
                        className={`absolute top-0 left-0 w-full h-full transition-all duration-300 ${isExpanded ? 'hidden' : 'bg-custom-gradient'} rounded-2xl`}></div>
                    {/* The "See More/Less" button */}
                    <div className={'react-css flex items-center justify-center md:mb-0 mb-5'}>
                        <ExpandButton
                            isExpanded={isExpanded}
                            onClick={handleButtonClick}
                            expandedText="Ascunde specificatiile"
                            collapsedText="Vezi toate specificatiile"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
