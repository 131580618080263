import React from 'react';

type ProductBrandProps = {
    appConfig: {
        productLogo: string;
        manufacturerName: string;
        baseUrl: string;
        productLogoExists: boolean;
    }
}

export const Brand = ({ appConfig }: ProductBrandProps) => {
    const formattedManufacturerName = appConfig.manufacturerName.replace(/ /g, '-').toLowerCase();
    const manufacturerUrl = `${appConfig.baseUrl}producator/${formattedManufacturerName}`;

    const generateProductLogoSrc = () => {
        if (appConfig.manufacturerName.includes(' ')) {
            const nameForLogo = appConfig.manufacturerName.replace(/ /g, '_').toLowerCase();
            return appConfig.productLogo.replace(appConfig.manufacturerName.toLowerCase(), nameForLogo);
        }
        return appConfig.productLogo;
    }

    const productLogoSrc = generateProductLogoSrc();

    return (
        <div className={'react-css rounded-xl bg-cards-bg p-4 block mb-6 mt-6 md:mt-0 shadow-custom-box-shadow md:shadow-none'}>
            <a className={'react-css flex flex-col justify-center gap-5 text-custom-gray hover:text-current'}
               href={manufacturerUrl}>
                {appConfig.productLogoExists ?
                    <img className={'h-28'} src={productLogoSrc} alt={appConfig.manufacturerName}/> :
                    <span className={'react-css text-center text-custom-gray font-nunito text-xl font-bold leading-4'}>{appConfig.manufacturerName}</span>
                }
                <span className={'react-css text-center text-custom-gray font-nunito text-lg leading-4'}>
                    Vezi produsele brandului
                </span>
            </a>
        </div>
    );
}
