import React, {useState, useMemo} from 'react';
import {ExpandButton} from '../../../../../general';
import {useProductAttributes} from '../../../state';

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const Prezentare = () => {
    const {productName, loading, shortDescription} = useProductAttributes() || {};
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleButtonClick = () => {
        toggleExpand();
        const element = document.getElementById('productDescription');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const decodedShortDescription = useMemo(() => {
        return shortDescription ? decodeHtml(shortDescription) : null;
    }, [shortDescription]);

    return (
        <div className={'relative rounded-xl bg-white md:mb-20 mb-10'} id={'productDescription'}>
            <div
                className={`react-css overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-full' : 'max-h-[450px]'} p-6`}>
                <div className={'react-css'}>
                    <h2 className={'react-css text-2xl font-black text-left text-custom-gray font-montserrat mb-8 flex'}>
                        <span className={'react-css w-2/5 md:block hidden'}>Prezentare</span>
                        <span
                            className={'react-css text-custom-gray font-montserrat font-bold text-center text-2xl'}>
                            {productName}
                        </span>
                    </h2>
                </div>
                {decodedShortDescription ? (
                    <div dangerouslySetInnerHTML={{__html: decodedShortDescription}}/>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
            {/* opacity efect over the closed div */}
            <div
                className={`absolute top-0 left-0 w-full h-full transition-all duration-300 ${isExpanded ? 'hidden' : 'bg-custom-gradient'} rounded-2xl`}></div>
            {/* The "See More/Less" button */}
            <div className={'react-css flex items-center justify-center md:mb-0 mb-5'}>
                <ExpandButton
                    isExpanded={isExpanded}
                    onClick={handleButtonClick}
                    expandedText="Ascunde descrierea"
                    collapsedText="Vezi toata descrierea"
                />
            </div>
        </div>
    );
}
