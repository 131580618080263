import React from 'react';

const errorTranslations = {
    "The requested qty is not available": "Cantitatea nu este disponibila",
};

const getErrorMessage = (message) => errorTranslations[message] || "";

export const Translate = ({ message }) => {
    return <div className={'text-red-500 text-sm mt-3 md:mt-0'}>
        {getErrorMessage(message)}
    </div>;
};
