import React, {useState} from 'react';
import {useCheckoutState} from "../../../../Checkout";
import {LoadingIcon} from "../../../../../general/assets/LoadingIcon";
import {ContextFormProvider, useProductAttributes} from "../../../state";
import {CrmLeadForm} from "../CrmForms/CrmLeadForm";
import {Translate} from "../../../../../general/Translate";

export const AddToCartButton = ({ oldCrmEnabled, newCrmEnabled }) => {
    const {handleAddToCart, addingToCart, errorMessage} = useCheckoutState();
    const [qty, setQty] = useState(1);

    const [showCrmLeadForm, setShowCrmLeadForm] = useState(false);

    const {
        loading,
        productAttributes,
        customAttributes,
        productSku
    } = useProductAttributes() || {};

    if (loading) return <LoadingIcon/>;

    const product_type_id = productAttributes?.type_id;

    return (
        <>
            {product_type_id != 'bundle' ? (
                <div className={'mt-6 mb-6 flex justify-left flex-col'}>
                    <div className={'react-addToCart flex justify-start w-full'}>
                        {customAttributes?.find(attr => attr.attribute_code === 'farapret')?.value != 'pret_stoc' ? (
                            <div>
                                {newCrmEnabled && (
                                    <>
                                        <button
                                            className={
                                                'flex justify-center items-center h-[50px] min-w-[280px] w-full bg-main-orange focus:outline-none ' +
                                                'focus:bg-main-orange active:bg-main-orange text-white text-xl font-montserrat ' +
                                                'font-bold rounded-full w-4/5 pl-2 pr-2 md:m-0 hover:bg-main-orange hover:text-white ' +
                                                'focus:text-white active:text-white shadow-first-btn-shadow border-main-orange transition-all duration-300 ease-in-out'
                                            }
                                            onClick={() => setShowCrmLeadForm(true)}
                                        >
                                            {'Cere o oferta'}
                                        </button>
                                        {showCrmLeadForm && (
                                            <ContextFormProvider productSku={productSku}>
                                                <div className={'fixed inset-0 flex items-center justify-center z-10'}>
                                                    <CrmLeadForm onClose={() => setShowCrmLeadForm(false)}/>
                                                </div>
                                            </ContextFormProvider>
                                        )}
                                    </>
                                )}

                            </div>
                        ) : (
                            <div className={'flex flex-row gap-4 w-full md:w-auto'}>
                                <input
                                    className={'qty-input bg-cards-bg rounded-xl w-[60px] h-[50px] text-center text-2xl font-montserrat font-bold ' +
                                        'border-2 border-solid border-border-color'}
                                    name={'qty'}
                                    value={qty}
                                    onChange={(e) => setQty(parseInt(e.target.value))}
                                />
                                <button
                                    className={
                                        'flex justify-center items-center h-[50px] min-w-[144px] md:min-w-[280px] w-full bg-main-orange focus:outline-none ' +
                                        'focus:bg-main-orange active:bg-main-orange text-white text-xl font-montserrat ' +
                                        'font-bold rounded-full w-4/5 md:m-0 hover:bg-main-orange hover:text-white hover:border-none ' +
                                        'focus:text-white active:text-white shadow-first-btn-shadow border-none transition-all duration-300 ease-in-out'
                                    }
                                    onClick={() => handleAddToCart({sku: productSku, quantity: qty ? qty : 1})}
                                >
                                    {productSku === addingToCart ? (
                                        <LoadingIcon/>
                                    ) : (
                                        <>
                                            <svg className={'mr-5'}
                                                 xmlns="http://www.w3.org/2000/svg" height="18" width="18"
                                                 viewBox="0 0 512 512">
                                                <path fill="#ffffff"
                                                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
                                            </svg>
                                            <span>{'Adauga in cos'}</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                    {errorMessage && (
                        <div className={'text-red-500 text-sm mt-3'}>
                            <Translate message={errorMessage}/>
                        </div>
                    )}
                </div>
            ) : (
                <div></div>
            )
            }
        </>
    );
};
