import React, {useState, useEffect} from 'react';
import {useCategoriesAndProductsContextProvider} from '../../state';

declare global {
    interface Window {
        BASE_URL: string;
    }
}

export const CategoryButton: React.FC = () => {
    const {firstLevelCategories, categoriesData, loading} = useCategoriesAndProductsContextProvider();
    const [selectedCategory, setSelectedCategory] = useState<any | null>(null);

    const handleCategoryClick = (categoryName) => {
        const category = categoriesData.find(cat => cat.name === categoryName);
        setSelectedCategory(category || null);
    };

    useEffect(() => {
        if (firstLevelCategories.length > 0 && categoriesData) {
            const initialCategory = categoriesData?.find(cat => cat.name === firstLevelCategories[0]);
            setSelectedCategory(initialCategory);
        }
    }, [firstLevelCategories, categoriesData]);

    if (loading)
        return (
            <div
                className={'react-css fixed top-0 left-0 w-full h-full flex flex-col text-white items-center justify-center bg-black bg-opacity-50 z-50'}>
                <span className={'react-css text-white text-2xl md:text-3xl font-bold'}>Promotiile se incarca!</span>
            </div>
        );

    return (
        <div>
            <div className={'flex flex-wrap flex-col md:flex-row gap-8 justify-items-center justify-center content-around'}>
                {firstLevelCategories.map((categoryName) => (
                    <button
                        key={categoryName}
                        onClick={() => handleCategoryClick(categoryName)}
                        className={'focus:outline-none focus:bg-black-friday-btn active:bg-black-friday-btn border-transparent ' +
                            'uppercase bg-black-friday-btn text-white text-3xl font-montserrat font-black p-6 rounded-3xl w-11/12 md:w-2/6 ' +
                            'min-w-[30%] md:max-w-lg min-h-[130px] hover:bg-black-friday-btn hover:text-white hover:shadow-black-friday-btn ' +
                            'transition-all duration-300 ease-in-out'}
                    >
                        {categoryName}
                    </button>
                ))}
            </div>
            <div className={'m-5'}>
                {selectedCategory?.children?.map((subCategory) => (
                    <div key={subCategory.id}>
                        <h2
                            className={'react-css text-left font-black font-montserrat text-3xl text-white tracking-wide mb-6 mt-12 md:mt-20'}
                        >
                            {subCategory.name}
                        </h2>
                        {/* Show products of each subcategory */}
                        <div
                            className={'grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 md:gap-11 gap-3'}>
                            {subCategory.products?.items?.map((product) => (
                                <div key={product.id}
                                     className={'react-css relative bg-white rounded-3xl md:p-4 md:mb-5 flex md:justify-between ' +
                                         'md:items-center md:flex-col md:h-auto md:hover:scale-105 transition-all hover:border ' +
                                         'border-slate-400 duration-300 ease-in-out md:grid md:grid-row-2 p-3 flex:row gap-4'}>
                                    {product.stock_status === "OUT_OF_STOCK" && (
                                        <div
                                            className={'absolute inset-0 flex items-center justify-center bg-black bg-opacity-10 z-10'}>
                                            <span
                                                className={'react-css text-rose-700 text-2xl font-black mr-48 md:mb-48 md:mr-0'}>OUT OF STOCK</span>
                                        </div>
                                    )}
                                    {product.price_range.minimum_price.regular_price.value !== product.price_range.minimum_price.final_price.value ? (
                                        <div>
                                            <p className={'react-css absolute h-[18px] w-[60px] rounded-t-xl left-0 top-0 text-white m-3 text-xs font-bold text-center bg-black-friday-btn'}>
                                                {`- ${(
                                                    ((product.price_range.minimum_price.regular_price.value - product.price_range.minimum_price.final_price.value) /
                                                        (product.price_range.minimum_price.regular_price.value || 1)) * 100
                                                ).toFixed(0)}%`}
                                            </p>
                                            <p className={'react-css absolute h-[18px] w-[60px] rounded-b-xl left-0 top-[18px] text-custom-gray m-3 text-xs font-bold text-center bg-first-btn-bg'}>
                                                {`PROMO`}
                                            </p>
                                        </div>
                                    ) : null }
                                    <a
                                        className={'md:row-span-1 md:h-[250px] flex justify-center items-start md:items-center font-montserrat w-4/12 md:w-auto'}
                                        href={`${window.BASE_URL}${product.url_rewrites[0]?.url || ''}`}
                                    >
                                        <img src={product.customProductImageUrl.url} alt={product.small_image.label}
                                             className={'w-auto object-contain object-center mt-8 md:mt-0 h-3/4'} loading="lazy"/>
                                    </a>
                                    <div className={'react-css row-span-1 flex flex-col gap-4 min-h-[230px] justify-center items-left md:items-center w-8/12 md:w-auto'}>
                                        <a className={'h-[90px] text-black hover:underline active:text-black'}
                                           href={`${window.BASE_URL}${product.url_rewrites[0]?.url || ''}`}>
                                            <h2 className={'react-css text-lg font-bold font-montserrat leading-7 md:mt-4 text-black text-left md:text-center'}>
                                                {product.name.length > 50 ? `${product.name.substring(0, 50)}...` : product.name}
                                            </h2>
                                        </a>
                                        <div className={'react-css mt-2 h-[60px] flex justify-left md:justify-center md:w-full'}>
                                            {product.price_range.minimum_price.regular_price.value === product.price_range.minimum_price.final_price.value || !product.special_price ? (
                                                <p className={'react-css text-custom-gray text-lg font-bold font-montserrat'}>
                                                    {parseFloat(String(product.price_range.minimum_price.regular_price.value)).toFixed(2)} Lei
                                                </p>
                                            ) : (
                                                <div className={'react-css flex flex-col w-full md:items-center'}>
                                                    <div className={'react-css flex justify-between md:justify-around items-center w-full'}>
                                                        <p className={'react-css font-bold text-text-gray text-sm line-through font-montserrat'}>
                                                            {parseFloat(String(product.price_range.minimum_price.regular_price.value)).toFixed(2)} Lei
                                                        </p>
                                                        <p className={'react-css bg-main-orange p-2 font-montserrat font-bold text-custom-gray ' +
                                                            'text-sm leading-none rounded-xl'}>
                                                            {'-'}{parseFloat(String(product.price_range.minimum_price.regular_price.value -
                                                            product.price_range.minimum_price.final_price.value)).toFixed(2)} Lei
                                                        </p>
                                                    </div>
                                                    <p className={'react-css text-lg font-bold text-red-500 font-montserrat'}>
                                                        {parseFloat(String(product.price_range.minimum_price.final_price.value)).toFixed(2)} Lei
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <p className={'react-css bg-custom-green p-2 font-montserrat font-bold text-white ' +
                                            'text-sm leading-none rounded-xl w-fit'}>
                                            {product.custom_attributes.find(attr => attr.attribute_code === 'status_stock')?.value}
                                        </p>
                                        <button
                                            className={
                                                'flex justify-center items-center h-[50px] min-w-[144px] md:min-w-[210px] bg-main-orange focus:outline-none ' +
                                                'focus:bg-main-orange active:bg-main-orange text-white text-xl font-montserrat ' +
                                                'font-bold rounded-full w-4/5 md:m-0 hover:bg-main-orange hover:text-white ' +
                                                'focus:text-white active:text-white shadow-first-btn-shadow border-main-orange transition-all duration-300 ease-in-out'
                                            }
                                            onClick={() => window.location.href = `${window.BASE_URL}${product.url_rewrites[0]?.url || ''}`}
                                        >
                                            Vezi detalii
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
