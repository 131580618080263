import React, {createContext, useContext, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {request} from '../../../Infrastructure';
import {createLeadsForm} from '../../queries';

export type CreateFormRequestType = {
    username: string;
    email: string;
    phone: string;
    address: string;
    region: string;
    message: string;
    productSku: string;
};

type ContextFormStateType = {
    handleCreateForm: (formData: { [p: number]: any; [p: symbol]: any; productSku: any }) => void;
    productSku: string;
    successMessage: string;
};

const ContextFormStateContext = createContext<ContextFormStateType | undefined>(undefined);

export const ContextFormProvider: React.FC<{ children: React.ReactNode, productSku: string}> = ({ children, productSku }) => {
    const [successMessage, setSuccessMessage] = useState('');

    const mutation = useMutation((formData: CreateFormRequestType) =>
        request(createLeadsForm, {input: formData}),
        {
            onSuccess: (data) => {
                if (data.data.createLeadsForm.success) {
                    setSuccessMessage(data.data.createLeadsForm.message);
                }
            }
        }
    );

    const handleCreateForm = (formData: CreateFormRequestType) => {
        mutation.mutate(formData);
    }

    return (
        <ContextFormStateContext.Provider value={{ handleCreateForm, productSku, successMessage }}>
            {children}
        </ContextFormStateContext.Provider>
    );
}

export const useContextFormState = () => {
    const context = useContext(ContextFormStateContext);
    if (context === undefined) {
        throw new Error("useContextFormState must be used within a ContextFormProvider");
    }
    return context;
}
