import React, {useMemo} from 'react';
import {useProductAttributes} from "../../../state";

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const ShortDescription = () => {
    const {description} = useProductAttributes() || {};

    if (!description) {
        return null
    }

    const decodedDescription = useMemo(() => {
        const trimmedDescription = description.length > 350 ?
            `${description.substring(0, 347)} ...` :
            description;

        return decodeHtml(trimmedDescription);
    }, [description]);

    return (
        <div className={'react-css flex flex-col items-left mb-6 mt-6'}>
            <div className={'text-start text-custom-gray font-nunito text-lg leading-7'}
                dangerouslySetInnerHTML={{__html: decodedDescription}}/>
            <a className={'text-start text-custom-gray font-nunito font-bold text-lg leading-7 ' +
                'active:text-custom-gray focus:text-custom-gray hover:text-custom-gray cursor-pointer'}
                href="#productDescription">Vezi toata descrierea</a>
        </div>
    );
};
