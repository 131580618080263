import React, {useState} from 'react';
import {ExpandButton, Stars} from "../../../../../general";
import {useProductAttributes} from '../../../state';

const getReviewsByRating = (reviews, rating: number) => {
    let totalReviews = reviews.length;
    let voteCount = 0;

    let filteredReviews = reviews.filter(review => {
        let ratingVotes = review.ratings_breakdown.filter(vote => Number(vote.value) === rating);
        voteCount += ratingVotes.length;
        return ratingVotes.length > 0;
    });

    let percentage = Math.round((filteredReviews.length / totalReviews) * 100);

    return {
        reviews: filteredReviews,
        percentage,
        voteCount
    };
};

export const Review = () => {
    const {productAttributes, error} = useProductAttributes() || {};
    const [isExpanded, setIsExpanded] = useState(false);

    if (error || !productAttributes || !productAttributes?.reviews || productAttributes?.reviews?.items.length === 0) {
        return null;
    }

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleButtonClick = () => {
        toggleExpand();  // This will toggle the expansion state

        const element = document.getElementById('react-reviews');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const numberOfReviews = productAttributes?.reviews?.items.length;
    const totalStarsValue = productAttributes?.reviews?.items.reduce((sum, review) => {
        const reviewStars = review.ratings_breakdown.reduce((rSum, vote) => rSum + Number(vote.value), 0) / review.ratings_breakdown.length;
        return sum + reviewStars;
    }, 0);

    const averageStars = numberOfReviews > 0 ? (totalStarsValue / numberOfReviews).toFixed(1) : "0.0";
    const averageStarsNumber = Number(averageStars);

    let fiveStarReviews = getReviewsByRating(productAttributes?.reviews?.items, 5);
    let fourStarReviews = getReviewsByRating(productAttributes?.reviews?.items, 4);
    let treeStarReviews = getReviewsByRating(productAttributes?.reviews?.items, 3);
    let twoStarReviews = getReviewsByRating(productAttributes?.reviews?.items, 2);
    let oneStarReviews = getReviewsByRating(productAttributes?.reviews?.items, 1);

    return (
        <div className={'react-css relative rounded-xl bg-white md:mb-10 mb-5'} id={'react-reviews'}>
            <div
                className={`react-css overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-full' : 'max-h-[450px]'} p-6`}>
                <div className={'react-css'}>
                    <h2 className={'react-css text-2xl font-black text-left text-custom-gray font-montserrat mb-8 flex'}>
                        <span className={'react-css w-2/5 md:block hidden'}>Evaluari</span>
                        <span
                            className={'react-css text-custom-gray font-montserrat font-bold text-center text-2xl'}></span>
                    </h2>
                </div>
                <div
                    className={'react-css border-b-2 mb-8 lex-row flex md:flex-row flex-col items-start justify-start md:gap-8 gap-5 pb-5'}>
                    <div className={'react-css flex flex-row items-center justify-start gap-5 '}>
                        <p className={'react-css text-custom-gray font-montserrat text-8xl italic font-black leading-none tracking-normal'}>
                            {averageStarsNumber}
                        </p>
                        <div className={'react-css flex flex-col items-start justify-start gap-2'}>
                        <span className={'react-css flex flex-row items-center justify-start gap-1 text-custom-gray'}>
                            <Stars totalStars={averageStarsNumber} starSize={'h-12 w-12'}/>
                        </span>
                            <p className={'react-css flex flex-row gap-2 items-center text-custom-gray font-nunito text-lg font-bold leading-tight tracking-normal'}>
                                <span className={'react-css font-nunito text-lg font-bold'}>{numberOfReviews}</span>
                                Evaluari
                            </p>
                        </div>
                    </div>
                    <div className={'react-css flex flex-col items-start justify-start gap-2'}>
                        <p className={'react-css flex flex-row items-center justify-start gap-2'}>
                            <span className={'react-css font-nunito text-lg font-bold'}>5 </span>
                            <span className={'react-css flex flex-row items-center justify-start gap-1'}>
                            <Stars totalStars={5} starSize={'h-4 w-4'}/>
                            </span>
                            <span
                                className={'react-css font-nunito text-lg font-bold'}>{fiveStarReviews.percentage}%</span>
                            <span
                                className={'react-css font-nunito text-lg font-bold'}>({fiveStarReviews.voteCount})</span>
                        </p>
                        <p className={'react-css flex flex-row items-center justify-start gap-2'}>
                            <span className={'react-css font-nunito text-lg font-bold'}>4 </span>
                            <span className={'react-css flex flex-row items-center justify-start gap-1'}>
                            <Stars totalStars={4} starSize={'h-4 w-4'}/>
                            </span>
                            <span
                                className={'react-css font-nunito text-lg font-bold'}>{fourStarReviews.percentage}%</span>
                            <span
                                className={'react-css font-nunito text-lg font-bold'}>({fourStarReviews.voteCount})</span>
                        </p>
                        <p className={'react-css flex flex-row items-center justify-start gap-2'}>
                            <span className={'react-css font-nunito text-lg font-bold'}>3 </span>
                            <span className={'react-css flex flex-row items-center justify-start gap-1'}>
                            <Stars totalStars={3} starSize={'h-4 w-4'}/>
                            </span>
                            <span
                                className={'react-css font-nunito text-lg font-bold'}>{treeStarReviews.percentage}%</span>
                            <span
                                className={'react-css font-nunito text-lg font-bold'}>({treeStarReviews.voteCount})</span>
                        </p>
                        <p className={'react-css flex flex-row items-center justify-start gap-2'}>
                            <span className={'react-css font-nunito text-lg font-bold'}>2 </span>
                            <span className={'react-css flex flex-row items-center justify-start gap-1'}>
                            <Stars totalStars={2} starSize={'h-4 w-4'}/>
                            </span>
                            <span
                                className={'react-css font-nunito text-lg font-bold'}>{twoStarReviews.percentage}%</span>
                            <span
                                className={'react-css font-nunito text-lg font-bold'}>({twoStarReviews.voteCount})</span>
                        </p>
                        <p className={'react-css flex flex-row items-center justify-start gap-2'}>
                            <span className={'react-css font-nunito text-lg font-bold'}>1 </span>
                            <span className={'react-css flex flex-row items-center justify-start gap-1'}>
                            <Stars totalStars={1} starSize={'h-4 w-4'}/>
                            </span>
                            <span
                                className={'react-css font-nunito text-lg font-bold'}>{oneStarReviews.percentage}%</span>
                            <span
                                className={'react-css font-nunito text-lg font-bold'}>({oneStarReviews.voteCount})</span>
                        </p>
                    </div>
                    <div className={'react-css flex flex-col items-start justify-start gap-3'}>
                        <p className={'react-css text-custom-gray font-nunito text-lg font-bold tracking-normal'}>
                            Detii sau ai folosit produsul?
                        </p>
                        <p className={'react-css text-custom-gray font-nunito text-lg font-normal tracking-normal mb-8'}>
                            Parerea ta ii poate ajuta pe ceilalti clienti.
                        </p>
                        <button onClick={() => window.location.href = '#review-form'}
                                className={
                                    "rounded-full bg-second-btn-bg hover:bg-second-btn-bg focus:bg-second-btn-bg " +
                                    "font-montserrat font-bold text-lg text-center text-white focus:text-white hover:text-white " +
                                    "shadow-second-btn-shadow transform hover:translate-y-1 mt-2 w-full py-3 px-4 md:mb-0 mb-5 " +
                                    "flex items-center justify-center z-2"
                                }
                                type="button"
                        >
                            <p className="react-css">
                                Scrie o evaluare
                            </p>
                        </button>
                    </div>
                </div>

                {productAttributes && productAttributes?.reviews && productAttributes?.reviews?.items.map((review, index) => (
                    <div className={'mb-8 border-b-2 flex md:flex-row flex-col'} key={index}>
                        <div
                            className={'flex md:flex-col flex-row gap-3 items-start justify-start md:w-3/12 mr-3 md:mb-0 mb-3'}>
                            <span
                                className={'react-css font-montserrat font-bold text-custom-gray text-lg'}>{review.nickname}</span>
                            <span className={'react-css text-lg font-nunito font-normal'}>{review.created_at}</span>
                        </div>
                        <div className={'flex flex-col gap-3 md:w-9/12'}>
                            <h2 className={'react-css font-montserrat font-bold text-custom-gray text-lg'}>{review.summary}</h2>
                            <div className={'mb-5'}>
                                {review.ratings_breakdown.map((rating, ratingIndex) => (
                                    <div className={'flex flex-row items-center justify-start space-x-8'}
                                         key={ratingIndex}>
                                        <div className={'flex flex-row items-center justify-start space-x-1'}>
                                            <Stars totalStars={Number(rating.value)} starSize={'h-8 w-8'}/>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <p className={'react-css font-nunito text-lg font-normal text-custom-gray mb-5'}>{review.text}</p>
                        </div>
                    </div>
                ))}
            </div>
            {/* opacity efect over the closed div */}
            <div
                className={`react-css absolute top-0 left-0 w-full h-full transition-all duration-300 ${isExpanded ? 'hidden' : 'bg-custom-gradient'} rounded-2xl`}></div>
            {/* The "See More/Less" button */}
            <div className={'react-css flex items-center justify-center md:mb-0 mb-5'}>
                <ExpandButton
                    isExpanded={isExpanded}
                    onClick={handleButtonClick}
                    expandedText="Ascunde recenziile"
                    collapsedText="Vezi toate recenziile"
                />
            </div>
        </div>
    );
}
