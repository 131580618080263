import React from 'react';
import {useBundleCheckoutState} from "../../../../Checkout";
import {ContextFormProvider, useProductAttributes} from "../../../state";
import {LoadingIcon} from "../../../../../general/assets/LoadingIcon";

export const ProductOptions = () => {
    const {bundleOptions, loading, error, total, handleCheckboxChange} = useBundleCheckoutState();
    const {customAttributes, productAttributes} = useProductAttributes();

    if (loading) {
        return (
            <div>
                <LoadingIcon/>
            </div>
        )
    }

    const isBundle = bundleOptions?.find(option => option.__typename === "BundleProduct");
    const bundleOptionsLength = bundleOptions?.[0]?.items?.[0]?.options.length;

    if (!isBundle) return <div></div>;

    const formatPrice = (price) => {
        const roundedPrice = Math.round(price * 100) / 100;
        const adjustedPrice = Math.abs(roundedPrice) < 0.01 ? 0 : roundedPrice;
        return new Intl.NumberFormat('ro-RO', {style: 'currency', currency: 'Lei'}).format(adjustedPrice);
    };

    const optionsTitle = bundleOptions[0]?.items[0]?.title;
    const inputType = bundleOptions[0]?.items[0]?.type;
    const isInStock = productAttributes?.stock_status

    return (
        <>
            {customAttributes?.find(attr => attr.attribute_code === 'farapret')?.value === 'pret_stoc' &&
            isInStock === 'IN_STOCK' && bundleOptionsLength > 0 ? (
                <div className={'flex flex-col gap-3'}>
                    {bundleOptions.map((bundleOption, index) => (
                        <div key={index}
                             className={'flex flex-col gap-3'}
                        >
                            {bundleOption.__typename === 'BundleProduct' && (
                                <p className={'react-css text-custom-gray font-nunito text-lg font-bold'}>
                                    {optionsTitle}
                                </p>
                            )}
                            {bundleOption.items?.map((item, itemIndex) => (
                                <div className={'flex flex-col gap-3'}
                                     key={itemIndex}>
                                    {item.options.map((option, optionIndex) => (
                                        <div key={optionIndex}
                                             className={'rounded-3xl shadow-custom-box-shadow ' +
                                                 'border-solid border-border-color content-center p-5 w-full'}
                                        >
                                            <div className={'flex flex-row justify-between items-center w-full'}>
                                                <div className={'flex flex-row gap-2 w-3/4 items-center'}>
                                                    {inputType === 'checkbox' && (
                                                        <input type="checkbox"
                                                               defaultChecked={true}
                                                               className={'m-0 p-0 top-0 w-6 h-6'}
                                                               onChange={(e) => handleCheckboxChange(
                                                                   option.uid,
                                                                   option.product.price_range.minimum_price.final_price.value,
                                                                   e.target.checked
                                                               )}
                                                        />
                                                    )}
                                                    <p className={'react-css text-custom-gray font-nunito text-base md:text-lg font-bold lowercase flex items-center'}>
                                                        {option.quantity} x {option.product.name}
                                                    </p>
                                                </div>
                                                <p className={'react-css text-custom-gray font-nunito text-sm md:text-lg font-bold w-1/4 flex items-center justify-end'}>
                                                    + {formatPrice(option.product.price_range.minimum_price.final_price.value)}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                    <div
                        className={'flex flex-row gap-2 items-center justify-start text-price-color font-montserrat text-2xl font-bold '}>
                        {formatPrice(total)}
                        <p className={'react-css text-text-gray font-montserrat text-sm font-light'}>CU TVA inclus</p>
                    </div>
                </div>

            ) : (
                <div></div>
            )}
        </>
    );
};


