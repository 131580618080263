import React from 'react';

type ConfigType = {
    appConfig: {
        baseUrl: string;
        link: string;
    }
}

export const DescarcaManual = ({ appConfig }: ConfigType) => {
    const pdfLink = `${appConfig.baseUrl}${appConfig.link.replace(' ', '_')}`;

    return (
        <div className={'react-css rounded-b-xl bg-cards-bg flex flex-row py-4 px-10 md:p-4 items-center gap-3'} id={'descarca-manual'}>
            <svg className={'fill-current text-gray-500 w-6 h-6'}
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7
                          64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4
                          24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6
                          0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z"/>
            </svg>
            <div className={'react-css relative group inline-block'}>
                <a className={'react-css text-custom-gray font-nunito text-lg leading-4 hover:text-current'}
                   href={pdfLink}
                   target="_blank">
                    Descarca manual
                </a>
                <span className={'absolute bg-white max-w-[360px] min-w-[250px] ' +
                    'p-4 z-50 hidden group-hover:block left-0 top-full text-left text-gray-500 ' +
                    'leading-7 border border-gray-400 mt-1.5 text-sm'}>
                        {`Descarca manualul produsului`}
                </span>
            </div>
        </div>
    );
}
