import React from "react";
import {CostTransport} from "./ProductInfo/CostTransport";
import {ProductAttributeProvider} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";

interface ProductSku {
    productSku: string;
}

const queryClient = client();

export const ProductInfo: React.FC<ProductSku> = ({productSku}) => {
    return (
        <QueryClientProvider client={queryClient}>
            <ProductAttributeProvider productSku={productSku}>
                <CostTransport/>
            </ProductAttributeProvider>
        </QueryClientProvider>
    );
}
