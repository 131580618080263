import React, {useState} from 'react';
import {ContextFormProvider} from "../../../Catalog";
import {CrmFormPacks} from "./CrmFormPacks";


export const FormApp = () => {
    const [showCrmLeadForm, setShowCrmLeadForm] = useState(true);

    return (
        <div className={'flex flex-col items-center mt-3 md:mt-8'}>
            <div className={'react-css flex flex-col gap-3'}>
                <p className={'react-css text-center font-medium font-montserrat text-base md:text-lg text-white tracking-wide mb-6'}>
                    {'Pentru a beneficia de oferta, contacteaza-ne: '}
                </p>
                <p className={'react-css text-center text-xl md:text-2xl text-white font-montserrat font-bold'}>
                    {'Telefonic'}
                </p>
                <div className="flex flex-col items-center content-center">
                    <div className="flex justify-center mb-3">
                        <a href="tel:0371.710.072">
                            <i className="fas fa-phone w-16 h-16 font-black text-white text-4xl leading-none bg-custom-green
                                        rounded-full p-3 border-0 box-border"></i>
                        </a>
                    </div>
                    <div className="flex flex-col justify-evenly flex-initial">
                        <a className={'font-montserrat text-white font-bold text-base md:text-lg leading-4.5 hover:text-white'}
                           href="tel:0371.710.072">0371.710.074
                        </a>
                    </div>
                </div>
            </div>

            <ContextFormProvider productSku={''}>
                <CrmFormPacks onClose={() => setShowCrmLeadForm(true)}/>
            </ContextFormProvider>
        </div>
    )
};


