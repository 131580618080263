import { useState, useEffect } from "react";

export const BreakPoints = {
    screenM: 768
};

type WindowSize = {
    width: number | undefined;
    height: number | undefined;
    isMobile: boolean | undefined;
};

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
        isMobile: undefined
    });
    const [breakPoint, setBreakPoint] = useState(BreakPoints.screenM);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                isMobile: window.innerWidth < breakPoint
            });
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, [breakPoint]); // Added breakPoint as a dependency

    return {
        width: windowSize.width,
        height: windowSize.height,
        setBreakPoint: setBreakPoint,
        isMobile: windowSize.isMobile
    };
};

