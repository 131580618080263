import React from 'react';
import {usePromoBulkProducts} from "../../state";

declare global {
    interface Window {
        BASE_URL: string;
    }
}

export const PromoPacks: React.FC = () => {
    const {productAttributes, appConfig, loading, error} = usePromoBulkProducts();

    if (loading) {
        return (
            <div
                className={'react-css fixed top-0 left-0 w-full h-full flex flex-col text-white items-center justify-center bg-black bg-opacity-50 z-50'}>
                <span className={'react-css text-white text-2xl md:text-3xl font-bold'}>Promotiile se incarca!</span>
            </div>
        );
    }

    return (
        <div>
            <div className={'m-5'}>
                {appConfig?.map((pack, index) => (
                    <div key={index}>
                        <p
                            className={'react-css text-left font-black font-montserrat text-2xl md:text-3xl text-white tracking-wide mb-6 mt-12 md:mt-20'}
                        >
                            {pack.pack_name}
                        </p>
                        <p
                            className={'react-css text-left font-medium font-montserrat text-base md:text-lg text-white tracking-wide mb-6'}
                        >
                            {'Avans '}
                            <span className={'react-css md:text-xl text-black-friday-btn font-bold'}>
                                {pack.required_value}{' euro'}
                            </span>
                            {' + tva.'}
                            {' Total '}
                            <span className={'react-css md:text-xl text-black-friday-btn font-bold'}>
                                {pack.total_value}{' euro'}
                            </span>
                            {' + tva.'}
                            {pack.gift && productAttributes.find(prod => prod.sku === pack.gift.trim()) && (
                                <span
                                    className={'react-css text-left font-bold font-montserrat text-base md:text-lg text-main-orange tracking-wide mb-6'}
                                >
                                {' + Cadou: '}
                                    {productAttributes.find(prod => prod.sku === pack.gift.trim()).name.split(' ')[0]}
                                    {' '}
                                    {pack.gift.trim()}
                            </span>
                            )}
                        </p>
                        <div
                            className={'grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 md:gap-11 gap-3'}>
                            {productAttributes
                                .filter(product =>
                                    [...pack.skus.split(",").map(sku => sku.trim()), pack.gift.trim()].includes(product.sku)
                                )
                                .sort((a, b) => {
                                    if (a.sku === pack.gift.trim()) return 1;
                                    if (b.sku === pack.gift.trim()) return -1;
                                    return pack.skus.split(",").map(sku => sku.trim()).indexOf(a.sku) - pack.skus.split(",").map(sku => sku.trim()).indexOf(b.sku);
                                })
                                .map(product => (
                                    <div key={product.id}
                                         className={'react-css relative bg-white rounded-3xl md:p-4 md:mb-5 flex md:justify-between ' +
                                             'md:items-center md:flex-col md:h-auto md:hover:scale-105 transition-all hover:border ' +
                                             'border-slate-400 duration-300 ease-in-out md:grid md:grid-row-2 p-3 flex:row gap-4'}>
                                        {product.sku === pack.gift.trim() && (
                                            <p className={'react-css font-montserrat font-bold absolute bottom-3 md:bottom-auto md:top-3 left-3 md:left-auto md:right-3 ' +
                                                'bg-red-500 text-white py-1 px-3 rounded-xl'}>
                                                CADOU
                                            </p>
                                        )}
                                    {product.price_range.minimum_price.regular_price.value !== product.price_range.minimum_price.final_price.value ? (
                                        <div>
                                            <p className={'react-css absolute h-[18px] w-[60px] rounded-t-xl left-0 top-0 text-white m-3 text-xs font-bold text-center bg-black-friday-btn'}>
                                                {`- ${(
                                                    ((product.price_range.minimum_price.regular_price.value - product.price_range.minimum_price.final_price.value) /
                                                        (product.price_range.minimum_price.regular_price.value || 1)) * 100
                                                ).toFixed(0)}%`}
                                            </p>
                                            <p className={'react-css absolute h-[18px] w-[60px] rounded-b-xl left-0 top-[18px] text-custom-gray m-3 text-xs font-bold text-center bg-first-btn-bg'}>
                                                {`PROMO`}
                                            </p>
                                        </div>
                                    ) : null}
                                    <a
                                        className={'md:row-span-1 md:h-[250px] flex justify-center items-start md:items-center font-montserrat w-4/12 md:w-auto'}
                                        href={`${window.BASE_URL}${product.url_rewrites[0]?.url || ''}`}
                                    >
                                        <img src={product.customProductImageUrl.url} alt={product.small_image.label}
                                             className={'w-auto object-contain object-center mt-8 md:mt-0 h-3/4'}
                                             loading="lazy"/>
                                    </a>
                                    <div
                                        className={'react-css row-span-1 flex flex-col gap-2 md:gap-4 min-h-[230px] justify-center items-left md:items-center w-8/12 md:w-auto'}>
                                        <a className={'h-[65px] md:h-[90px] text-black hover:underline active:text-black'}
                                           href={`${window.BASE_URL}${product.url_rewrites[0]?.url || ''}`}>
                                            <h2 className={'react-css text-sm md:text-lg font-bold font-nunito md:leading-7 md:mt-4 text-black text-left md:text-center'}>
                                                {product.name.length > 50 ? `${product.name.substring(0, 50)}...` : product.name}
                                            </h2>
                                        </a>
                                        <div
                                            className={'react-css mt-2 h-[60px] flex justify-left md:justify-center md:w-full'}>
                                            {product.price_range.minimum_price.regular_price.value === product.price_range.minimum_price.final_price.value || !product?.special_price ? (
                                                <p className={'react-css text-custom-gray text-base md:text-lg font-bold font-montserrat'}>
                                                    {parseFloat(String(product.price_range.minimum_price.regular_price.value)).toFixed(2)} Lei
                                                </p>
                                            ) : (
                                                <div className={'react-css flex flex-col w-full md:items-center'}>
                                                    <div
                                                        className={'react-css flex justify-between md:justify-around items-center w-full'}>
                                                        <p className={'react-css font-bold text-text-gray text-xs md:text-sm line-through font-montserrat'}>
                                                            {parseFloat(String(product.price_range.minimum_price.regular_price.value)).toFixed(2)} Lei
                                                        </p>
                                                        <p className={'react-css bg-main-orange p-2 font-montserrat font-bold text-custom-gray ' +
                                                            'text-xs md:text-sm leading-none rounded-xl'}>
                                                            {'-'}{parseFloat(String(product.price_range.minimum_price.regular_price.value -
                                                            product.price_range.minimum_price.final_price.value)).toFixed(2)} Lei
                                                        </p>
                                                    </div>
                                                    <p className={'react-css text-base md:text-lg font-bold text-red-500 font-montserrat'}>
                                                        {parseFloat(String(product.price_range.minimum_price.final_price.value)).toFixed(2)} Lei
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <p className={'react-css bg-custom-green p-2 font-montserrat font-bold text-white ' +
                                            ' text-xs md:text-sm leading-none rounded-xl w-fit'}>
                                            {product.custom_attributes.find(attr => attr.attribute_code === 'status_stock')?.value}
                                        </p>
                                        <button
                                            className={
                                                'flex justify-center items-center h-[40px] md:h-[50px] min-w-[144px] md:min-w-[210px] bg-main-orange focus:outline-none ' +
                                                'focus:bg-main-orange active:bg-main-orange text-white text-base md:text-xl font-montserrat ' +
                                                'font-bold rounded-full w-4/5 md:m-0 hover:bg-main-orange hover:text-white ' +
                                                'focus:text-white active:text-white shadow-first-btn-shadow border-main-orange transition-all duration-300 ease-in-out'
                                            }
                                            onClick={() => window.location.href = `${window.BASE_URL}${product.url_rewrites[0]?.url || ''}`}
                                        >
                                            Vezi detalii
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
