import React from 'react';
import {
    AndreiMoiseanu,
    NicoletaNitescu,
    MarianUngureanu,
    SebastianFrancu,
    StefanRadu,
    VasileMitu,
    DanielCrisan,
    MihaitaBudaca,
    SilviuColotin
} from '../../../../../general';
import {useProductAttributes} from '../../../state';

export const Contacts = () => {
    const {productAttributes} = useProductAttributes() || {};
    const contacts = [
        {
            image: MihaitaBudaca,
            name: "Mihaita Budaca",
            locations: "BUCURESTI, DOLJ, GORJ, ILFOV, MEHEDINTI, OLT"
        },
        {
            image: AndreiMoiseanu,
            name: "Andrei Moiseanu",
            locations: "BUZAU, PRAHOVA, VRANCEA"
        },
        {
            image: SebastianFrancu,
            name: "Sebastian Francu",
            locations: "BRAILA, GIURGIU, IALOMITA, TELEORMAN"
        },
        {
            image: StefanRadu,
            name: "Stefan Radu",
            locations: "ARAD, BIHOR, BISTRITA NASAUD, CLUJ, MARAMURES, MURES, SALAJ, SATU MARE "
        },
        {
            image: NicoletaNitescu,
            name: "Nicoleta Nitescu",
            locations: "BRASOV, COVASNA, GALATI, HARGHITA, SIBIU, ARGES, DAMBOVITA, VALCEA"
        },
        {
            image: VasileMitu,
            name: "Vasile Mitu",
            locations: "CALARASI, CONSTANTA, TULCEA"
        },
        {
            image: MarianUngureanu,
            name: "Marian Ungureanu",
            locations: "BOTOSANI, NEAMT, SUCEAVA"
        },
        {
            image: SilviuColotin,
            name: "Silviu Colotin",
            locations: "BACAU, IASI, VASLUI"
        },
        {
            image: DanielCrisan,
            name: "Daniel Crisan",
            locations: "ALBA, CARAS-SEVERIN, HUNEDOARA, TIMIS"
        },
    ]

    if (!productAttributes || !productAttributes?.custom_attributes) {
        return null;
    }

    const f_tip_produs_attribute = productAttributes?.custom_attributes.find(
        attribute => attribute.attribute_code === 'f_tip_produs'
    );

    const f_tip_produs_value = f_tip_produs_attribute ? f_tip_produs_attribute.value : null;

    if (f_tip_produs_value === 'Utilaje') {
        return (
            <div>
                <div
                    className={'flex flex-col space-y-2.5 rounded-xl bg-cards-bg shadow-custom-box-shadow md:my-6 px-8 py-5 block'}>
                    <p className={'text-center text-slate-700 font-bold text-lg leading-normal mb-4 font-montserrat'}>
                        Contacteaza-ne telefonic pentru pret sau detalii
                    </p>
                    <div className="content">
                        <div className="flex flex-row items-center content-center">
                            <div className="mr-5 w-1/5 flex justify-center">
                                <a href="tel:0371.710.074" className={'hover:text-current'}>
                                    <i className="fas fa-phone w-16 h-16 font-black text-white text-4xl leading-none bg-custom-green rounded-full p-4 border-0 box-border"></i>
                                </a>
                            </div>
                            <div className="flex flex-col justify-evenly flex-initial w-4/5">
                                <a className={'font-montserrat text-custom-gray font-bold text-base leading-4.5 hover:text-current'}
                                   href="tel:0371.710.074">0371.710.074</a>
                                <a href="tel:0371.710.074"
                                   className={'text-custom-gray font-normal text-sm leading-4.5 font-nunito hover:text-current'}>
                                    Vei putea directiona apelul catre reprezentantul zonei geografice din care faci
                                    parte
                                </a>
                            </div>
                        </div>
                    </div>
                    {contacts.map(contact =>
                        <div className="content" key={contact.name}>
                            <div className="flex flex-row items-center content-center">
                                <div className="mr-5 w-1/5 flex justify-center">
                                    <contact.image/>
                                </div>
                                <div className="flex flex-col justify-evenly flex-initial w-4/5">
                                    <p className="font-montserrat text-custom-gray font-bold text-sm leading-7">{contact.name}</p>
                                    <p className="text-custom-gray font-normal text-xs leading-4.5 font-nunito">{contact.locations}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    } else if (f_tip_produs_value === 'Piese') {
        return (
            <div
                className="flex flex-col space-y-2.5 rounded-xl bg-cards-bg shadow-custom-box-shadow md:my-6 px-8 py-5 block">
                <p className="text-center text-slate-700 font-bold text-lg leading-normal mb-4 font-montserrat">
                    Contacteaza-ne telefonic pentru pret sau detalii
                </p>
                <div className="content">
                    <div className="flex flex-row items-center content-center">
                        <div className="mr-5 w-1/5 flex justify-center">
                            <a href="tel:0371.710.072">
                                <i className="fas fa-phone w-16 h-16 font-black text-white text-4xl leading-none bg-custom-green rounded-full p-4 border-0 box-border"></i>
                            </a>
                        </div>
                        <div className="flex flex-col justify-evenly flex-initial w-4/5">
                            <a className={'font-montserrat text-custom-gray font-bold text-base leading-4.5 hover:text-current'}
                               href="tel:0371.710.072">0371.710.072
                            </a>
                            <a className={'text-custom-gray font-normal text-sm leading-4.5 font-nunito hover:text-current'}
                               href="tel:0371.710.072">
                                Vanzari online piese
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className="flex flex-col space-y-2.5 rounded-xl bg-cards-bg shadow-custom-box-shadow md:my-6 px-8 py-5 block">
                <p className="text-center text-slate-700 font-bold text-lg leading-normal mb-4 font-montserrat">
                    Contacteaza-ne telefonic pentru pret sau detalii
                </p>
                <div className="content">
                    <div className="flex flex-row items-center content-center">
                        <div className="mr-5 w-1/5 flex justify-center">
                            <a href="tel:0371.710.071">
                                <i className="fas fa-phone w-16 h-16 font-black text-white text-4xl leading-none bg-custom-green rounded-full p-4 border-0 box-border"></i>
                            </a>
                        </div>
                        <div className="flex flex-col justify-evenly flex-initial w-4/5">
                            <a className={'font-montserrat text-custom-gray font-bold text-base leading-4.5 hover:text-current'}
                               href="tel:0371.710.071">0371.710.071
                            </a>
                            <a className={'text-custom-gray font-normal text-sm leading-4.5 font-nunito hover:text-current'}
                               href="tel:0371.710.071">
                                Vanzari online (utilaje gradina, motoutilaje si unelte)
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
