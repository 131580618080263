import React from 'react';
import {useProductAttributes} from "../../../state";

export const CostTransport = () => {
    const {categories} = useProductAttributes() || {};

    const TRACTOARE_ID = 167;
    const UTILAJE_AGRICOLE_ID = 171;

    if (!categories) {
        return null
    }

    const renderDeliveryCost = () => {
        const hasCategory = (id) => categories.some(category => category.id === id);

        if (hasCategory(UTILAJE_AGRICOLE_ID)) {
            return (
                <p className={'react-css font-bold text-left text-black-friday-btn font-nunito text-xl'}>
                    {'Cost livrare: 500 ron cu tva inclus (oriunde in tara).'}
                </p>
            );
        }

        if (hasCategory(TRACTOARE_ID)) {
            return (
                <p className={'react-css font-bold text-left text-black-friday-btn font-nunito text-xl'}>
                    {'Cost livrare si punere in functiune: 900 ron cu tva inclus (oriunde in tara).'}
                </p>
            );
        }

        return null;
    };

    return (
        <div className={'react-css flex flex-col items-left mb-2'}>
            {renderDeliveryCost()}
        </div>
    );
};
