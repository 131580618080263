import React, {useState} from 'react';
import {Blocks} from './Blocks';
import {PromoBulkProductsProvider} from "../../state";
import {Arrows} from '../../../../general';
import {useWindowSize} from '../../../../general';
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";
import {PromoPacks} from "./PromoPacks";
import {FormApp} from "../PromoCrmLeadForm/FormApp";

const queryClient = client();

type BulkPacksOptions = {
    appConfig: {
        optionsData: [];
        promoText: string;
    }
}

export const BulkSalePage: React.FC<BulkPacksOptions> = ({appConfig}: BulkPacksOptions) => {
    const {isMobile} = useWindowSize();

    return (
        <QueryClientProvider client={queryClient}>
            <PromoBulkProductsProvider appConfig={appConfig.optionsData || []}>
                <div
                    className={'react-css mt-0 md:mt-10 pt-3 pb-3 pl-4 bg-background-gray md:bg-inherit flex items-center flex-row gap-2'}>
                    {isMobile ? (
                        <Arrows
                            arrowType={'LeftArrow'}
                            fill={'#848484'}
                            arrowSize={'h-3 w-3'}
                        />
                    ) : (
                        <Arrows
                            arrowType={'LeftArrow'}
                            fill={'#F0F0F0'}
                            arrowSize={'h-3 w-3'}
                        />
                    )}
                    <a className={'react-css font-nunito font-bold text-text-gray md:text-white text-base ' +
                        ' active:text-text-gray md:active:text-white hover:text-text-gray md:hover:text-white ' +
                        ' focus:text-text-gray md:focus:text-white decoration-current'}
                       href={`${window.BASE_URL}home`}>Inapoi la pagina principala </a>
                </div>
                <div>
                    <div
                        className="react-css flex flex-col gap-2 md:gap-6 justify-center items-center w-full pl-3 pr-3 md:p-0">
                        <Blocks/>
                        <p className={'react-css text-left font-black font-nunito text-sm text-white tracking-wide mb-0 md:mb-6 w-full'}>
                            {appConfig?.promoText}
                        </p>
                    </div>
                    <div>
                        <PromoPacks/>
                    </div>
                    <div>
                        <FormApp/>
                    </div>
                </div>
            </PromoBulkProductsProvider>
        </QueryClientProvider>
    )
};


