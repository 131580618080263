import React, {useRef} from 'react';

interface PhotoUploadButtonProps {
    onPhotosSelected: (files: FileList) => void;
}

export const PhotoUploadButton: React.FC<PhotoUploadButtonProps> = ({onPhotosSelected}) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleButtonClick = (e) => {
        e.preventDefault();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            onPhotosSelected(event.target.files);
        }
    };

    return (
        <div className={'flex justify-center align-center items-center w-full'}>
            <input
                type="file"
                multiple
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{display: 'none'}}
                accept="image/*"
            />
            <button onClick={handleButtonClick}
                    className={
                        "rounded-full bg-second-btn-bg hover:bg-second-btn-bg focus:bg-second-btn-bg " +
                        "font-montserrat font-bold text-lg text-center text-white focus:text-white hover:text-white " +
                        "shadow-second-btn-shadow transform hover:translate-y-1 mt-2 w-80 py-3 px-4 " +
                        "flex items-center justify-center "
                    }
                    type="button"
            >{'Incarca fotografii'}</button>
        </div>
    );
};
