import React, {useEffect, useState} from 'react';
import {useContextFormState} from '../../../state';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from "@hookform/error-message";

export const CrmLeadForm = ({onClose}) => {
    const {handleCreateForm, productSku, successMessage} = useContextFormState();

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors}
    } = useForm();

    useEffect(() => {
        setValue('productSku', productSku);
    }, [productSku, setValue]);

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (successMessage) {
            setIsModalOpen(true);  // Open the modal when there is a success message
            setFormSubmitted(true);  // Indicate that the form has been submitted
        }
    }, [successMessage]);

    const onSubmit = (formData) => {
        handleCreateForm(formData);
    };

    const handleClose = () => {
        onClose();
        setIsModalOpen(false);
    };

    return (

        <div
            className={'relative react-css flex items-center justify-center align-center gap-2 w-full md:w-1/2 md:max-w-4xl pl-4 pr-4'}>
            {!formSubmitted ? (
                <div
                    className={'react-css crm-lead-form flex flex-col space-y-2.5 rounded-xl bg-cards-bg ' +
                        'shadow-custom-box-shadow md:my-6 px-3 py-3 md:px-8 md:py-5 block w-full'}>
                    <button onClick={onClose} className={'absolute font-2xl right-[5%] top=[5%]'}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className={'h-8 w-8'}
                             viewBox="0 0 512 512">
                            <path
                                d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7
                                 64-64V96c0-35.3-28.7-64-64-64H64zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47
                                 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4
                                 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6
                                 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
                        </svg>
                    </button>
                    <p className={'text-center text-slate-700 font-bold text-lg leading-normal mb-4'}>
                        {'Cere oferta de pret pentru acest produs'}
                    </p>
                    <form className={'flex flex-col gap-4'}
                          onSubmit={handleSubmit(onSubmit)}>
                        <div className={'flex flex-col gap-1'}>
                            <input
                                {...register('username', {
                                    required: 'Nume si prenume este obligatoriu',
                                    maxLength: {
                                        value: 30,
                                        message: 'Te rugam sa introduci mai putin de 30 caractere.'
                                    }
                                })}
                                className={'react-css text-custom-gray font-montserrat font-normal text-left text-base ' +
                                    'rounded-3xl border border-gray-400 w-full h-12 p-4'}
                                placeholder="Nume si prenume *"
                            />
                            <ErrorMessage
                                errors={errors}
                                name="username"
                                render={({message}) => <span
                                    className={'error-message text-red-500 text-base'}>{message}</span>}
                            />
                        </div>
                        <div className={'flex flex-col gap-1'}>
                            <input
                                {...register('email', {
                                    required: "Acest camp este obligatoriu",
                                    maxLength: {
                                        value: 30,
                                        message: 'Te rugam sa introduci mai putin de 30 caractere.'
                                    }
                                })}
                                className={'react-css text-custom-gray font-montserrat font-normal text-left text-base ' +
                                    'rounded-3xl border border-gray-400 w-full h-12 p-4'}
                                placeholder="Email *"
                            />
                            <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({message}) => <span
                                    className={'error-message text-red-500 text-base'}>{message}</span>}
                            />
                        </div>
                        <div className={'flex flex-row gap-4'}>
                            <div className={'flex flex-col gap-1 w-1/2'}>
                                <input
                                    {...register('phone', {
                                        required: "Acest camp este obligatoriu",
                                        maxLength: {
                                            value: 15,
                                            message: "Te rugam sa introduci un nr de telefon valid."
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "Te rugam sa introduci un nr de telefon valid."
                                        }
                                    })}
                                    className={'react-css text-custom-gray font-montserrat font-normal text-left text-base ' +
                                        'rounded-3xl border border-gray-400 w-full h-12 p-4'}
                                    placeholder="Numar telefon *"
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="phone"
                                    render={({message}) => <span
                                        className={'error-message text-red-500 text-base'}>{message}</span>}
                                />
                            </div>
                            <div className={'flex flex-col gap-1 w-1/2'}>
                                <select
                                    {...register('region', {
                                        required: "Selectarea unui județ este obligatorie",
                                    })}
                                    className={'react-css text-custom-gray font-montserrat font-normal text-left text-base ' +
                                        'rounded-3xl border border-gray-400 w-full h-12 pl-4 pr-4'}>
                                    <option value="">Alege Județul</option>
                                    <option value="In afara României">In afara României</option>
                                    <option value="Alba">Alba</option>
                                    <option value="Arad">Arad</option>
                                    <option value="Arges">Arges</option>
                                    <option value="Bacau">Bacau</option>
                                    <option value="Bihor">Bihor</option>
                                    <option value="Bistrita-Nasaud">Bistrita-Nasaud</option>
                                    <option value="Botosani">Botosani</option>
                                    <option value="Brasov">Brasov</option>
                                    <option value="Braila">Braila</option>
                                    <option value="Buzau">Buzau</option>
                                    <option value="Calarasi">Calarasi</option>
                                    <option value="Caras-Severin">Caras-Severin</option>
                                    <option value="Cluj">Cluj</option>
                                    <option value="Constanta">Constanta</option>
                                    <option value="Covasna">Covasna</option>
                                    <option value="Dambovita">Dambovita</option>
                                    <option value="Dolj">Dolj</option>
                                    <option value="Galati">Galati</option>
                                    <option value="Giurgiu">Giurgiu</option>
                                    <option value="Gorj">Gorj</option>
                                    <option value="Harghita">Harghita</option>
                                    <option value="Hunedoara">Hunedoara</option>
                                    <option value="Ialomita">Ialomita</option>
                                    <option value="Iasi">Iasi</option>
                                    <option value="Ilfov">Ilfov</option>
                                    <option value="Maramures">Maramures</option>
                                    <option value="Mehedinti">Mehedinti</option>
                                    <option value="Mures">Mures</option>
                                    <option value="Neamt">Neamt</option>
                                    <option value="Olt">Olt</option>
                                    <option value="Prahova">Prahova</option>
                                    <option value="Satu Mare">Satu Mare</option>
                                    <option value="Salaj">Salaj</option>
                                    <option value="Sibiu">Sibiu</option>
                                    <option value="Suceava">Suceava</option>
                                    <option value="Teleorman">Teleorman</option>
                                    <option value="Timis">Timis</option>
                                    <option value="Tulcea">Tulcea</option>
                                    <option value="Vaslui">Vaslui</option>
                                    <option value="Valcea">Valcea</option>
                                    <option value="Vrancea">Vrancea</option>
                                    <option value="Bucuresti">Bucuresti</option>
                                </select>
                                <ErrorMessage
                                    errors={errors}
                                    name="region"
                                    render={({message}) => <span
                                        className={'error-message text-red-500 text-base'}>{message}</span>}
                                />
                            </div>
                        </div>
                        <div className={'flex flex-col gap-1'}>
                            <input
                                {...register('address', {
                                    maxLength: {
                                        value: 50,
                                        message: 'Te rugam sa introduci mai putin de 50 caractere.'
                                    }
                                })}
                                className={'react-css text-custom-gray font-montserrat font-normal text-left text-base ' +
                                    'rounded-3xl border border-gray-400 w-full h-12 p-4'}
                                placeholder="Adresa (optional)"
                            />
                            <ErrorMessage
                                errors={errors}
                                name="address"
                                render={({message}) => <span
                                    className={'error-message text-red-500 text-base'}>{message}</span>}
                            />
                        </div>
                        <div className={'flex flex-col gap-1'}>
                            <textarea
                                {...register('message', {
                                    maxLength: {
                                        value: 200,
                                        message: 'Te rugam sa introduci mai putin de 200 caractere.'
                                    }
                                })}
                                className={'react-css text-custom-gray font-montserrat font-normal text-left text-base ' +
                                    'rounded-3xl border border-gray-400 w-full h-auto min-w-[150px] p-4'}
                                placeholder="Mesajul tau ... (optional)"
                                rows={6}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="message"
                                render={({message}) => <span
                                    className={'error-message text-red-500 text-base'}>{message}</span>}
                            />
                        </div>
                        <div
                            className={'react-css flex items-center justify-center align-center gap-2 w-full mb-6'}>
                            <button
                                className={
                                    "rounded-full bg-first-btn-bg hover:bg-first-btn-bg focus:bg-first-btn-bg " +
                                    "font-montserrat font-bold text-lg text-center text-white focus:text-white hover:text-white " +
                                    "shadow-first-btn-shadow transform hover:translate-y-1 mt-2 w-80 py-3 px-4 " +
                                    "flex items-center justify-center"}
                                type="submit">{'Trimite cererea'}
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <div className={'z-10'}>
                    {isModalOpen && (
                        <div
                            className={'react-css fixed top-0 left-0 w-full h-full flex items-center justify-center'}>
                            <div
                                className={'react-css bg-background-gray p-6 rounded-xl shadow-lg w-4/5 md:w-96 p-6 z-50 absolute top-24 ' +
                                    'flex items-center justify-center flex-col border-solid border-2 border-main-orange rounded-xl'}>
                                <p className={'text-center'}>{successMessage}</p>
                                <button
                                    onClick={() => handleClose()}
                                    className={'react-css mt-4 bg-main-orange text-white px-4 py-2 rounded hover:bg-main-orange'}
                                    type="button"
                                >
                                    {'Inchide'}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
