import React from 'react';
import {useProductAttributes} from "../../../state";

export const SafetyStihl = () => {
    const {customAttributes, categories} = useProductAttributes() || {};
    const manufacturer = customAttributes?.find(
        attribute => attribute.attribute_code === 'manufacturer'
    )?.value;
    const hasCategory26 = categories?.some(category => category.id === 26);

    if (manufacturer === 'Stihl' && hasCategory26) {
        return (
            <div
                className={'react-css rounded-xl bg-cards-bg flex flex-col md:p-4 p-6 mt-6 items-center gap-3 shadow-custom-box-shadow md:shadow-none'}>
                <div className={'flex flex-row gap-2'}>
                    <svg className={'fill-current text-gray-500 w-6 h-6'}
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3
                        0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7
                        24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                    </svg>
                    <span className={'text-start text-custom-gray font-nunito text-base font-bold leading-6'}>
                        {'MASURI DE SIGURANTA'}
                    </span>
                </div>
                <div className="content">
                    <p className={'text-start text-custom-gray font-nunito text-lg leading-6'}>
                        {'Pentru a cunoaste pericolele unui motofierastrau, este necesara o instruire.'}<br/>
                        {'Deasemenea, trebuie respectate cerintele pentru imbracamintea de siguranta.'}<br/>
                        {'Daca informatiile nu sunt clare, va recomandam sa-l ridicati din magazinul nostru fizic (dealer autorizat STIHL).'}<br/>
                        <br/>
                        {'Mai multe informatii pe canalul de'}
                        <a className={'ml-1'}
                           rel="nofollow"
                           href="https://www.youtube.com/user/STIHLRomania"
                           target="_blank"
                           title="YouTube Stihl Romania"
                        >
                            {'YouTube Stihl Romania'}
                        </a>
                    </p>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
