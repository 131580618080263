import React from "react";
import {Contacts} from "./ProductInfo/Contacts";
import {ProductAttributeProvider} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";
import {SmallMap} from "./ProductSideBar/SmallMap";
import {useWindowSize} from '../../../../general';
import {ShortDescription} from "./ProductInfo/ShortDescription";
import {ProductOptions} from "./ProductInfo/ProductOptions";
import {BundleCheckoutProvider, CheckoutProvider} from "../../../Checkout";
import {BundleAddToCartButton} from "./AddToCart/BundleAddToCartButton";

interface ProductSku {
    productSku: string;
    oldCrmEnabled?: boolean;
    newCrmEnabled?: boolean;
}

const queryClient = client();

export const ProductShortInfo: React.FC<ProductSku> = ({productSku, oldCrmEnabled, newCrmEnabled}) => {
    const {isMobile} = useWindowSize();

    return (
        <QueryClientProvider client={queryClient}>
            <BundleCheckoutProvider productSku={productSku}>
                <ProductAttributeProvider productSku={productSku}>
                    {isMobile ? (
                        <div>
                            <ProductOptions/>
                            <BundleAddToCartButton oldCrmEnabled={oldCrmEnabled} newCrmEnabled={newCrmEnabled}/>
                            <div className={'mb-4 mt-4'}>
                                <ShortDescription/>
                            </div>
                            <Contacts/>
                            <SmallMap/>
                        </div>

                    ) : (
                        <div>
                            <ProductOptions/>
                            <BundleAddToCartButton oldCrmEnabled={oldCrmEnabled} newCrmEnabled={newCrmEnabled}/>
                            <ShortDescription/>
                            <Contacts/>
                        </div>
                    )}
                </ProductAttributeProvider>
            </BundleCheckoutProvider>
        </QueryClientProvider>
    );
}
