import React from 'react';

interface ConfigType {
    imageUrl: string;
}

declare global {
    interface Window {
        imageUrlConfig: string;
    }
}

export const Seap = (props: ConfigType) => {
    const imageUrl = window.imageUrlConfig || props.imageUrl;

    if (!imageUrl) {
        return null;
    }

    return (
        <div className={'react-css rounded-xl bg-cards-bg flex flex-row p-4 mt-6 mb-6 items-center gap-3 shadow-custom-box-shadow md:shadow-none'}>
            <div className={'flex items-center justify-center w-1/2'}>
                <img src={imageUrl} alt="SEAP Logo" />
            </div>
            <div className={'react-css relative group inline-block w-1/2 leading-tight'}>
                <p className={'react-css text-center text-custom-gray font-nunito text-xs'}>
                    Suntem prezenti pe
                </p>
                <p className={'react-css font-bold text-center text-custom-gray font-nunito text-xs'}>
                    SISTEMUL ELECTRONIC DE ACHIZITII PUBLICE
                </p>
            </div>
        </div>
    );
};
