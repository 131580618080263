import React from 'react';
import {SmallMap} from './ProductSideBar/SmallMap';
import {SafetyStihl} from "./ProductSideBar/SafetyStihl";
import {Seap} from './ProductSideBar/Seap';
import {ProductAttributeProvider} from "../../state";
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";
import {useWindowSize} from '../../../../general';

interface ProductSku {
    productSku: string;
}

const queryClient = client();

export const ProductSideBar: React.FC<ProductSku> = ({productSku}) => {
    const imageUrl = window.imageUrlConfig;
    const {isMobile} = useWindowSize();

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <ProductAttributeProvider productSku={productSku}>
                    {isMobile ? (
                        <div>
                            <SafetyStihl/>
                            <Seap imageUrl={imageUrl}/>
                        </div>

                    ) : (
                        <div>
                            <SafetyStihl/>
                            <Seap imageUrl={imageUrl}/>
                            <SmallMap/>
                        </div>
                    )}
                </ProductAttributeProvider>
            </QueryClientProvider>
        </div>
    );
};


