import React from 'react';
import {useProductAttributes} from '../../../state';
import {CardItem, useWindowSize} from "../../../../../general";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import {Navigation} from 'swiper/modules';

export const UpsellProducts = () => {
    const {upsellProducts} = useProductAttributes() || {};
    const {isMobile} = useWindowSize();
    const itemsToShow = isMobile ? 2 : 5;
    const gapBetween = isMobile ? 5 : 15;

    if (!upsellProducts.length) return null;

    const filteredProducts = upsellProducts.filter(product =>
        product?.custom_attributes.find(attr => attr.attribute_code === 'info_disp')?.value !== 'Nu'
    );

    return (
        <div id={'upsell'}
             className={'react-css mt-5 md:mt-14'}>
            <h2 className={'react-css text-custom-gray font-montserrat font-bold text-left text-2xl mb-3 md:mb-9'}>
                Produse asociate
            </h2>
            <Swiper navigation={true}
                    modules={[Navigation]}
                    slidesPerView={itemsToShow}
                    spaceBetween={gapBetween}
                    freeMode={true}
                    className="react-slider">
                {filteredProducts.map((product) => (
                    <SwiperSlide key={product.id}>
                        <CardItem product={product}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
